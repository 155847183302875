export interface GamePlay {
  game: 'Roulette' | 'BlackJack';
  player: string;
  bet: string;
  payout: string;
  result: string;
  timestamp: number;
  txId: string;
  payoutTxId: string;
}

export enum GamesStats {
  Global = '0',
  Roulette = '1',
  BlackJack = '2'
}

export enum GamePeriod {
  Week = '0',
  Month = '1',
  Custom = '2'
}
