import { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { dayTimespan, getEgldValue } from 'helpers';
import { GamePlay, GamesStats } from 'types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = '#FFFFFF';

export const DashboardChart = ({
  game,
  transactions,
  currentPeriodDates,
  currentChart,
  onChangeChart
}: {
  game: GamesStats;
  transactions: GamePlay[];
  currentPeriodDates: { start: number; end: number };
  currentChart: 'games' | 'egld';
  onChangeChart: (chart: 'games' | 'egld') => void;
}) => {
  const [displayEgldVolume, setDisplayEgldVolume] = useState(
    currentChart === 'games' ? '0' : '1'
  );

  const labels: { dayStart: number; dayEnd: number }[] = [];

  const now = Math.floor(new Date().setUTCHours(0, 0, 0, 0) / 1000);

  if (!currentPeriodDates)
    currentPeriodDates = { start: now - dayTimespan * 7, end: now };

  currentPeriodDates.start = Math.floor(
    new Date(currentPeriodDates.start * 1000).setUTCHours(0, 0, 0, 0) / 1000
  );
  currentPeriodDates.end = Math.floor(
    new Date(currentPeriodDates.end * 1000).setUTCHours(23, 59, 59, 0) / 1000
  );

  for (
    let timestamp = currentPeriodDates.start;
    timestamp < currentPeriodDates.end;
    timestamp += dayTimespan
  ) {
    labels.push({ dayStart: timestamp, dayEnd: timestamp + dayTimespan - 1 });
  }

  let datasets: any[] = [];

  if (game === GamesStats.BlackJack) {
    datasets = [GetBJDataset(labels, transactions, displayEgldVolume === '1')];
  } else if (game === GamesStats.Roulette) {
    datasets = [
      GetRouletteDataset(labels, transactions, displayEgldVolume === '1')
    ];
  } else {
    datasets = [
      // GetTotalDataset(labels, transactions),
      GetRouletteDataset(labels, transactions, displayEgldVolume === '1'),
      GetBJDataset(labels, transactions, displayEgldVolume === '1')
    ];
  }

  const data = {
    labels: labels.map((l) => new Date(l.dayStart * 1000).toLocaleDateString()),
    // labels: labels.map((l) => `${new Date(l.dayStart * 1000).toLocaleString()}-${new Date(l.dayEnd * 1000).toLocaleString()}`),
    datasets
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        // labels: { color: '#FFFFFF' },
        display: true /*game === GamesStats.Global*/
      }
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return (
    <div className='card px-3 pb-3 shadow-sm border-0 mb-4'>
      <div className='dashboard-table-title text-white-80 mb-1 d-flex justify-content-between'>
        <div>GAMES PLAYED</div>

        {/* <div className='d-flex flex-wrap justify-content-center gap-sm games-graph-select'>
          <button
            className={`btn btn-primary bg-gradient ${
              displayEgldVolume === '1'
                ? 'btn-active'
                : ''
            }`.trim()}
            type='button'
            onClick={() => setDisplayEgldVolume('1')}
            disabled={displayEgldVolume === '1'}
          >
            Egld
          </button>
          <button
            className={`btn btn-primary bg-gradient ${
              displayEgldVolume === '0'
                ? 'btn-active'
                : ''
            }`.trim()}
            type='button'
            onClick={() => setDisplayEgldVolume('0')}
            disabled={displayEgldVolume === '0'}
          >
            Games
          </button>
        </div> */}

        <select
          className='bg-gradient games-graph-select'
          defaultValue={displayEgldVolume}
          onChange={(e) => {
            setDisplayEgldVolume(e.target.value);
            onChangeChart(e.target.value === '1' ? 'egld' : 'games');
          }}
        >
          <option value='1'>Egld volume</option>
          <option value='0'>Games volume</option>
        </select>
      </div>
      <div className='chart-container'>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

function GetRouletteDataset(
  labels: { dayStart: number; dayEnd: number }[],
  transactions: GamePlay[],
  displayEgldVolume: boolean
) {
  return {
    label: 'Roulette',
    data: labels.map((day) => {
      const txs = transactions.filter(
        (t) =>
          t.game === 'Roulette' &&
          t.timestamp >= day.dayStart &&
          t.timestamp < day.dayEnd
      );
      if (displayEgldVolume)
        return txs
          .reduce((res, game) => res + getEgldValue(game.bet), 0)
          .toFixed(2);
      return txs.length;
    }),
    borderColor: 'rgb(254 203 0)',
    backgroundColor: 'rgb(254 203 0 / 85%)'
  };
}

function GetBJDataset(
  labels: { dayStart: number; dayEnd: number }[],
  transactions: GamePlay[],
  displayEgldVolume: boolean
) {
  return {
    label: 'Black Jack',
    data: labels.map((day) => {
      const txs = transactions.filter(
        (t) =>
          t.game === 'BlackJack' &&
          t.timestamp >= day.dayStart &&
          t.timestamp < day.dayEnd
      );
      if (displayEgldVolume)
        return txs
          .reduce((res, game) => res + getEgldValue(game.bet), 0)
          .toFixed(2);
      return txs.length;
    }),
    borderColor: 'rgba(45, 140, 213, 1)',
    backgroundColor: 'rgba(45, 140, 213, 0.8)'
  };
}

// function GetTotalDataset(
//   labels: { dayStart: number; dayEnd: number }[],
//   transactions: GamePlay[]
// ) {
//   return {
//     label: 'Total',
//     data: labels.map(
//       (day) =>
//         transactions.filter(
//           (t) => t.timestamp >= day.dayStart && t.timestamp < day.dayEnd
//         ).length
//     ),
//     borderColor: '#FFFFFFB0',
//     backgroundColor: '#FFFFFF80'
//   };
// }
