import { useEffect, useState } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { dayTimespan, toUnixTime } from 'helpers';
import { routeNames } from 'routes';
import { GamePeriod, GamesStats } from 'types';

export const PeriodSelector = ({
  currentPage,
  onChangePeriod,
  currentPeriod,
  currentPeriodDates,
  isLoading,
  chartType
}: {
  currentPage: string;
  onChangePeriod: (
    startDate: number,
    endDate: number,
    type: GamePeriod
  ) => void;
  currentPeriod: GamePeriod;
  currentPeriodDates: { start: number; end: number };
  isLoading: boolean;
  chartType: 'games' | 'egld';
}) => {
  const [gameStat, setGameStat] = useState<string>(
    currentPage === routeNames.roulette
      ? GamesStats.Roulette
      : currentPage === routeNames.blackjack
      ? GamesStats.BlackJack
      : GamesStats.Global
  );
  const [isCustom, setCustom] = useState(false);
  const [customStart, setcustomStart] = useState(0);
  const [customEnd, setcustomEnd] = useState(0);

  const navigate = useNavigate();

  const onSelectGame = (game: string) => {
    const options: NavigateOptions = {
      state: {
        currentPeriod,
        startDate: currentPeriodDates.start,
        endtDate: currentPeriodDates.end,
        chartType
      }
    };

    if (game === GamesStats.Roulette) navigate(routeNames.roulette, options);
    else if (game === GamesStats.BlackJack)
      navigate(routeNames.blackjack, options);
    else navigate(routeNames.home, options);
  };

  const onWeekSelect = () => {
    if (isCustom) {
      setCustom(false);
    }

    if (currentPeriod !== GamePeriod.Week) {
      const nowDate = Math.floor(Date.now() / 1000);

      onChangePeriod(nowDate - dayTimespan * 7, nowDate, GamePeriod.Week);
    }
  };

  const onMonthSelect = () => {
    if (isCustom) {
      setCustom(false);
    }

    if (currentPeriod !== GamePeriod.Month) {
      const nowDate = Math.floor(Date.now() / 1000);

      onChangePeriod(nowDate - dayTimespan * 30, nowDate, GamePeriod.Month);
    }
  };

  const onCustomSelect = () => {
    setCustom(!isCustom);
  };

  useEffect(() => {
    if (!isCustom) {
      setcustomStart(0);
      setcustomEnd(0);
    }

    if (isCustom && customStart && customEnd && customEnd >= customStart) {
      onChangePeriod(customStart, customEnd, GamePeriod.Custom);
    }
  }, [isCustom, customStart, customEnd]);

  return (
    <div className='d-flex justify-content-between flex-wrap mb-4 gap-md'>
      <div className='card shadow-sm period-selector'>
        <select
          className='bg-gradient'
          defaultValue={gameStat}
          onChange={(e) => {
            setGameStat(e.target.value);
            onSelectGame(e.target.value);
          }}
          disabled={isLoading}
        >
          <option value={GamesStats.Global}>Global</option>
          <option value={GamesStats.Roulette}>Roulette</option>
          <option value={GamesStats.BlackJack}>BlackJack</option>
        </select>
      </div>
      <div className='card shadow-sm period-selector align-items-center flex-wrap flex-row justify-content-around'>
        <div className='text-uppercase'>
          {isCustom && (
            <div className='d-flex align-items-center gap-sm flex-wrap'>
              From
              <input
                type='date'
                className='btn bg-gradient px-1 input-dates'
                // min="2022-01-01"
                onChange={(e) =>
                  setcustomStart(toUnixTime(new Date(e.target.value)))
                }
                disabled={isLoading}
                placeholder={new Date(
                  (currentPeriodDates?.start ?? 0) * 1000 ||
                    new Date().getTime()
                ).toLocaleDateString()}
              />
              To
              <input
                type='date'
                className='btn bg-gradient px-1 input-dates'
                // min="2022-01-01"
                onChange={(e) =>
                  setcustomEnd(toUnixTime(new Date(e.target.value)))
                }
                disabled={isLoading}
                placeholder={new Date(
                  (currentPeriodDates?.end ?? 0) * 1000 || new Date().getTime()
                ).toLocaleDateString()}
              />
            </div>
          )}
          {!isCustom && (
            <>
              {`${new Date(
                currentPeriodDates.start * 1000
              ).toLocaleDateString()} - ${new Date(
                currentPeriodDates.end * 1000
              ).toLocaleDateString()}`}
            </>
          )}
        </div>
        <div className='d-flex flex-wrap justify-content-center gap-sm'>
          <button
            className={`btn btn-primary bg-gradient ${
              currentPeriod === GamePeriod.Week && !isCustom ? 'btn-active' : ''
            }`.trim()}
            type='button'
            onClick={onWeekSelect}
            disabled={isLoading}
          >
            Week
          </button>
          <button
            className={`btn btn-primary bg-gradient ${
              currentPeriod === GamePeriod.Month && !isCustom
                ? 'btn-active'
                : ''
            }`.trim()}
            type='button'
            onClick={onMonthSelect}
            disabled={isLoading}
          >
            Month
          </button>
          <button
            className={`btn btn-primary bg-gradient ${
              currentPeriod === GamePeriod.Custom || isCustom
                ? 'btn-active'
                : ''
            }`.trim()}
            type='button'
            onClick={onCustomSelect}
            disabled={isLoading}
          >
            Custom
          </button>
        </div>
      </div>
    </div>
  );
};
