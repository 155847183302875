// import { useState } from 'react';
// import { useGetNetworkConfig } from 'hooks';

import { cropAddress } from 'helpers';
import { GamePlay } from 'types';

// export const useWalletData = (erdData: any) => {
//   const {
//     network: { apiAddress }
//   } = useGetNetworkConfig();

//   const [heroTag, setHeroTag] = useState('');

//   const erdAddr = erdData?.erd ? erdData.erd.toString() : '';
//   if (erdAddr && !heroTag) {
//     fetchWalletData(apiAddress, erdAddr).then((tag) => setHeroTag(tag));
//   }

//   return {
//     heroTag
//   };
// };

const walletTags: any = {};

export const useWalletData = () => {
  function fetchWalletData(apiAddress: string, erdAddr: string) {
    // return Promise.resolve('');
    return fetch(`${apiAddress}/accounts/${erdAddr}?fields=username`)
      .then((response) => response.json())
      .then((data: any) => {
        if (data?.username) {
          return `${data?.username?.replace('.elrond', '')}`;
        }
        return '';
      })
      .catch(() => {
        return '';
        /* ignore */
      });
  }

  function preComputeWallets(comptedTxs: GamePlay[]) {
    if (!comptedTxs) return [];

    const newTxs: GamePlay[] = JSON.parse(JSON.stringify(comptedTxs));

    const wallets = newTxs
      .map((tx) => tx.player)
      .filter((value, index, array) => array.indexOf(value) === index)
      .map((erd) => ({ erd, tag: erd }));

    for (const wallet of wallets) {
      wallet.tag = cropAddress(wallet.erd);
    }

    for (const tx of newTxs) {
      tx.player = wallets.find((w) => w.erd === tx.player)?.tag || tx.player;
    }

    return newTxs;
  }

  async function computeWallets(apiAddress: string, comptedTxs: GamePlay[]) {
    if (!comptedTxs) return [];

    const newTxs: GamePlay[] = JSON.parse(JSON.stringify(comptedTxs));

    const wallets = newTxs
      .map((tx) => tx.player)
      .filter((value, index, array) => array.indexOf(value) === index)
      .map((erd) => ({ erd, tag: erd }));

    for (const wallet of wallets) {
      let tag: string = walletTags[wallet.erd];
      if (!tag) {
        tag = await fetchWalletData(apiAddress, wallet.erd);
        if (!tag) tag = cropAddress(wallet.erd);
        walletTags[wallet.erd] = tag;
      }
      wallet.tag = tag;
    }

    for (const tx of newTxs) {
      tx.player = wallets.find((w) => w.erd === tx.player)?.tag || tx.player;
    }

    return newTxs;
  }

  return {
    preComputeWallets,
    computeWallets
  };
};
