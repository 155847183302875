import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetRouletteData } from 'api/Roulette';
import { rouletteAddress } from 'config';
import { routeNames } from 'routes';
import { GamePeriod, GamesStats } from 'types';
import {
  DashboardLayout,
  DashboardStats,
  DashboardTable,
  PeriodSelector
} from './components';

export const Roulette = () => {
  const {
    fetchTransactions,
    transactions,
    lastTransactions,
    isLoading,
    loadingPercent,
    error
  } = useGetRouletteData();

  const location = useLocation();

  const [startDate, setStartDate] = useState(location?.state?.startDate ?? 0);
  const [endtDate, setEndDate] = useState(location?.state?.endtDate ?? 0);
  const [currentPeriod, setCurrentPeriod] = useState(
    location?.state?.currentPeriod ?? GamePeriod.Week
  );
  const [chartType, setChartType] = useState<'games' | 'egld'>(
    location?.state?.chartType ?? 'egld'
  );

  useEffect(() => {
    if (!startDate || !endtDate) {
      const now = Math.floor(Date.now() / 1000);
      const day = 60 * 60 * 24;

      setStartDate(now - day * 7);
      setEndDate(now);
      setCurrentPeriod(GamePeriod.Week);
    } else if (startDate && endtDate) {
      fetchTransactions(10, startDate, endtDate);
    }
  }, [startDate, endtDate]);

  const changePeriod = useCallback(
    (startTimeSpan: number, endTimeSpan: number, type: GamePeriod) => {
      setStartDate(startTimeSpan);
      setEndDate(endTimeSpan);
      setCurrentPeriod(type);
    },
    []
  );

  return (
    <DashboardLayout
      contractAddress={rouletteAddress}
      isLoading={isLoading}
      loadingPercent={loadingPercent}
      error={error}
      transactions={transactions}
      setChartType={setChartType}
      chartType={chartType}
      filters={
        <PeriodSelector
          currentPage={routeNames.roulette}
          currentPeriod={currentPeriod}
          currentPeriodDates={{ start: startDate, end: endtDate }}
          onChangePeriod={changePeriod}
          isLoading={isLoading}
          chartType={chartType}
        />
      }
      stats={<DashboardStats transactions={transactions} />}
      game={GamesStats.Roulette}
      currentPeriodDates={{ start: startDate, end: endtDate }}
    >
      <DashboardTable
        transactions={transactions}
        lastTransactions={lastTransactions}
      />
    </DashboardLayout>
  );
};
