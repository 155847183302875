export * from './sdkDappHelpers';
export * from './constants';

export const base64ToString = (data: string) =>
  Buffer.from(data, 'base64').toString('ascii');

export const hexToString = (data: string) =>
  Buffer.from(data, 'hex').toString('utf8');

export const cropAddress = (erdAddress: string) => {
  if (!erdAddress) return '';
  const start = erdAddress.substring(0, 6);
  const end = erdAddress.substring(erdAddress.length - 4, erdAddress.length);
  return `${start}...${end}`;
};

export function minSize(sizeA: number, sizeB: number) {
  return sizeA < sizeB ? sizeA : sizeB;
}

export function maxSize(sizeA: number, sizeB: number) {
  return sizeA > sizeB ? sizeA : sizeB;
}

export function getEgldValue(value: string) {
  return isNaN(parseInt(value)) ? 0 : parseInt(value) / 10 ** 18;
}

export function toUnixTime(value: Date) {
  return Math.floor(value.getTime() / 1000);
}
