import React from 'react';
import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';

export const Footer = () => {
  return (
    <footer className='text-center mb-4'>
      <div>
        {/* <a
          {...{
            target: '_blank'
          }}
          className='d-flex align-items-center'
          href='https://multiversx.com/'
        > */}
        Made with <HeartIcon className='mx-1' /> by SAC Team
        {/* </a> */}
      </div>
    </footer>
  );
};
