// import { faRing, faDice } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /*Navbar as BsNavbar,*/ NavItem, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dAppSubTitle, dAppTitle } from 'config';
import { routeNames } from 'routes';

export const Navbar = () => {
  return (
    <div className='font-weight-bold py-3 text-uppercase'>
      <div className='container flex-wrap'>
        <div className='row'>
          <div className='col-12 mx-auto d-flex flex-wrap justify-content-between'>
            <Link
              className='d-flex flex-column navbar-brand'
              to={routeNames.home}
            >
              <div className='dapp-name text-white text-wrap'>{dAppTitle}</div>
              <div className='text-white'>{dAppSubTitle}</div>
            </Link>

            <Nav className='align-items-center gap-md'>
              <NavItem>
                <a
                  href={routeNames.websiteBlackjack}
                  target='_blank'
                  className='nav-link nav-bj text-center'
                >
                  <span>PLAY</span>
                  <span>BlackJack</span>
                </a>
              </NavItem>
              <NavItem>
                <a
                  href={routeNames.websiteRoulette}
                  target='_blank'
                  className='nav-link nav-roulette text-center'
                >
                  <span>PLAY</span>
                  <span>Roulette</span>
                </a>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
    </div>
  );
};
