// import { ReactComponent as HeartIcon } from '../../assets/img/heart.svg';

export const InfoMessage = () => {
  const message = process.env.REACT_APP_INFO_MESSAGE;

  if (!message) return <></>;

  return (
    // <div className='container'>
    //   <div className='row'>
    //     <div className='col-11 mx-auto'>
    <div className='info-message'>
      <div>{message}</div>
      {/* <div>
        Made with <HeartIcon className='mx-1' /> by SAC Team
      </div> */}
    </div>
    //     </div>
    //   </div>
    // </div>
  );
};
