import { useMemo, useState } from 'react';
import { AiOutlinePieChart } from 'react-icons/ai';
import { BsBarChartLine } from 'react-icons/bs';
import { GiMoneyStack, GiOpenTreasureChest } from 'react-icons/gi';
import { ImStatsDots } from 'react-icons/im';
import { LiaDiceD6Solid } from 'react-icons/lia';
import { TbPigMoney } from 'react-icons/tb';
import { TfiGame } from 'react-icons/tfi';
import { getEgldValue } from 'helpers';
import { GamePlay } from 'types';

interface DashboardStatsProps {
  transactions: GamePlay[];
}

export const DashboardStats = ({ transactions }: DashboardStatsProps) => {
  const [extStatsNb, setExtStatsNb] = useState(4);
  const [extStatsVol, setExtStatsVol] = useState(4);
  const [extStatsPay, setExtStatsPay] = useState(4);

  const betVolume = useMemo(() => {
    if (!transactions) return 0;
    return transactions
      .reduce((res, game) => res + getEgldValue(game.bet), 0)
      .toFixed(2);
  }, [transactions]);

  const fees = useMemo(() => {
    if (!transactions) return 0;
    return transactions
      .reduce((res, game) => res + getEgldValue(game.bet) * 0.7 * 0.07, 0)
      .toFixed(2);
  }, [transactions]);

  const gamesNumber = useMemo(() => {
    return transactions?.length ?? 0;
  }, [transactions]);

  const maxPayout = useMemo(() => {
    if (!transactions) return { amount: 0, player: undefined };
    const amount = Math.max(
      ...transactions.map((game) => getEgldValue(game.payout))
    );
    const playerGame = transactions.find(
      (game) => amount <= getEgldValue(game.payout)
    );
    return { amount, player: playerGame?.player };
  }, [transactions]);

  const players = useMemo(() => {
    if (!transactions) return [];
    return transactions
      .map((game) => game.player)
      .filter((value, index, array) => array.indexOf(value) === index)
      .map((player) => ({
        player,
        games: transactions.filter((tx) => tx.player === player)
      }));
  }, [transactions]);

  const playersGamesNumber = useMemo(() => {
    if (!players) return [];
    return players
      .map((player) => ({
        player: player.player,
        games: player.games.length
      }))
      .sort((a, b) => b.games - a.games);
  }, [players]);

  const playersBets = useMemo(() => {
    if (!players) return [];
    return players
      .map((player) => ({
        player: player.player,
        games: player.games.reduce(
          (res, game) => res + getEgldValue(game.bet),
          0
        )
      }))
      .sort((a, b) => b.games - a.games)
      .map((player) => ({
        player: player.player,
        games: player.games.toFixed(2)
      }));
  }, [players]);

  const playersPayouts = useMemo(() => {
    if (!players) return [];
    return players
      .map((player) => ({
        player: player.player,
        games: player.games.reduce(
          (res, game) => res + getEgldValue(game.payout),
          0
        )
      }))
      .filter((player) => player.games > 0)
      .sort((a, b) => b.games - a.games)
      .map((player) => ({
        player: player.player,
        games: player.games.toFixed(2)
      }));
  }, [players]);

  return (
    <div className='row dashboard-stats'>
      <div className='col'>
        <div className='card shadow-sm border-0 pb-2'>
          <div className='card-body p-0'>
            <div className='border-0 px-4 py-2 mt-1'>
              <div className='align-items-center card-title d-flex gap-md table-col text-left'>
                {/* <TopInfo contractAddress={contractAddress} /> */}
                {/* <Actions /> */}
                <AiOutlinePieChart size={30} />
                Main Statistics
              </div>
              <div className='d-flex align-items-center card-body py-2 px-0 gap-md'>
                {/* <img
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAPFBMVEVHcEweHh4eHh4eHh4eHh4eHh4dHR0eHh4eGBkeAAAeCg8eODUi4Mkgh3oizbgj/uUhuKUfTkgj+N4hq5r/bRTxAAAACHRSTlMAatD/y2YJ70waAJwAAADaSURBVHgBjZMFEsMwDASNrWQnxv//tWrVjCF4Yd2GBIIklT6UseKr11uf6v0igPwLQgirLyWFuQaU0Dd6CgC6Me4QegD8siKHGMd18dABGGKMKaPjm3MqMQZsAORYCsXoMaSV6O9lhgb4SrEfU2vZzoZvQFdjYYMPSeP0m+gDe38bdnmADYkhsz0AjORQSrOfA83mvw1+egXbOrWPJGQCms2HWB0OwJaoUlvKfAf8Ux041Uuf6las0hcrEo2n5YZW7scNc6F7QF37RshrwN6O3v3wkqw5tpUk8wMGDxg9tzDfsQAAAABJRU5ErkJggg=='
                  data-atf='1'
                  data-frt='0'
                  className='mr-3'
                /> */}
                <div className='picto'>
                  {/* <ImStatsDots size={20} color='#25f8de' /> */}
                  <ImStatsDots size={20} color='#f1bb11' />
                </div>
                {betVolume} EGLD VOLUME
              </div>
              <div className='d-flex align-items-center card-body py-2 px-0 gap-md'>
                <div className='picto'>
                  <GiOpenTreasureChest size={20} color='#f1bb11' />
                </div>
                {fees} EGLD REWARDS FROM FEES
              </div>
              <div className='d-flex align-items-center card-body py-2 px-0 gap-md'>
                <div className='picto'>
                  <TfiGame size={20} color='#f1bb11' />
                </div>
                {gamesNumber} GAMES PLAYED
              </div>
              <div className='d-flex align-items-center card-body py-2 px-0 gap-md'>
                <div className='picto'>
                  <GiMoneyStack size={20} color='#f1bb11' />
                </div>
                <span>
                  {maxPayout.amount} EGLD - BIGGEST WIN
                  <br />
                  {maxPayout.player ?? ''}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col'>
        <div className='card shadow-sm border-0 pb-2'>
          <div className='card-body p-0'>
            <div className='border-0 px-4 py-2 mt-1'>
              <div className='align-items-center card-title d-flex gap-md table-col text-left'>
                <LiaDiceD6Solid size={30} />
                Top players
                <br />
                Number of GAMES
              </div>
              {playersGamesNumber
                .slice(0, extStatsNb)
                .map((playerGamesNumber, index) => (
                  <div className='card-body py-2 px-0' key={index}>
                    {index + 1}. {playerGamesNumber.player}
                    <span className='stat-value ml-2 text-no-break'>
                      {playerGamesNumber.games} Games
                    </span>
                  </div>
                ))}
              {playersGamesNumber.length > 4 && (
                <div className='mt-2 text-right'>
                  <button
                    className='btn px-2 py-0 stats-plus'
                    type='button'
                    onClick={() =>
                      setExtStatsNb(
                        extStatsNb === 4 ? playersGamesNumber.length : 4
                      )
                    }
                  >
                    {extStatsNb === 4 ? '+' : '-'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='col'>
        <div className='card shadow-sm border-0 pb-2'>
          <div className='card-body p-0'>
            <div className='border-0 px-4 py-2 mt-1'>
              <div className='align-items-center card-title d-flex gap-md table-col text-left'>
                <BsBarChartLine size={30} />
                Top players
                <br />
                Volume PLAYED
              </div>
              {playersBets.slice(0, extStatsVol).map((playerBets, index) => (
                <div className='card-body py-2 px-0' key={index}>
                  {index + 1}. {playerBets.player}
                  <span className='stat-value-egld ml-2 text-no-break'>
                    {playerBets.games} EGLD
                  </span>
                </div>
              ))}
              {playersBets.length > 4 && (
                <div className='mt-2 text-right'>
                  <button
                    className='btn px-2 py-0 stats-plus'
                    type='button'
                    onClick={() =>
                      setExtStatsVol(extStatsVol === 4 ? playersBets.length : 4)
                    }
                  >
                    {extStatsVol === 4 ? '+' : '-'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='col'>
        <div className='card shadow-sm border-0 pb-2'>
          <div className='card-body p-0'>
            <div className='border-0 px-4 py-2 mt-1'>
              <div className='align-items-center card-title d-flex gap-md table-col text-left'>
                <TbPigMoney size={30} />
                Top players
                <br />
                EGLD payout
              </div>
              {playersPayouts
                .slice(0, extStatsPay)
                .map((playerPayouts, index) => (
                  <div className='card-body py-2 px-0' key={index}>
                    {index + 1}. {playerPayouts.player}
                    <span className='stat-value-payout ml-2 text-no-break'>
                      {playerPayouts.games} EGLD
                    </span>
                  </div>
                ))}
              {playersPayouts.length > 4 && (
                <div className='mt-2 text-right'>
                  <button
                    className='btn px-2 py-0 stats-plus'
                    type='button'
                    onClick={() =>
                      setExtStatsPay(
                        extStatsPay === 4 ? playersPayouts.length : 4
                      )
                    }
                  >
                    {extStatsPay === 4 ? '+' : '-'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='card shadow-sm border-0 ml-auto'>
            <div className='card-body p-0'>
              <div className='card border-0 px-4 py-2'>
                <div className='card-title'>Block 3</div>
              </div>
            </div>
          </div> */}
    </div>
  );
};
