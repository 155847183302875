import { dAppName } from 'config';
import { RouteType } from 'types';
import { withPageTitle } from './components/PageTitle';

import { BlackJack, Roulette, DashboardPage } from './pages';

export const routeNames = {
  home: '/',
  roulette: '/roulette',
  blackjack: '/blackjack',
  websiteRoulette: 'https://roulette.salvagames.com/',
  websiteBlackjack: 'https://bj.salvagames.com/'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.home,
    title: 'Games Dashboard',
    component: DashboardPage
  },
  {
    path: routeNames.roulette,
    title: 'Roulette Dashboard',
    component: Roulette
  },
  {
    path: routeNames.blackjack,
    title: 'Black-Jack Dashboard',
    component: BlackJack
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
