import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetBlackJackData } from 'api/BlackJack';
import { useGetRouletteData } from 'api/Roulette';
import { blackJackAddress } from 'config';
import { routeNames } from 'routes';
import { GamePeriod, GamePlay, GamesStats } from 'types';
import {
  DashboardLayout,
  DashboardStats,
  DashboardTable,
  PeriodSelector
} from './components';

export const DashboardPage = () => {
  const {
    fetchTransactions: fetchTransactionsRL,
    transactions: transactionsRL,
    lastTransactions: lastTransactionsRL,
    isLoading: isLoadingRL,
    loadingPercent: loadingPercentRL,
    error: errorRL
  } = useGetRouletteData();

  const {
    fetchTransactions: fetchTransactionsBJ,
    transactions: transactionsBJ,
    lastTransactions: lastTransactionsBJ,
    isLoading: isLoadingBJ,
    loadingPercent: loadingPercentBJ,
    error: errorBJ
  } = useGetBlackJackData();

  const location = useLocation();

  const [transactions, setTransactions] = useState<GamePlay[]>([]);
  const [lastTransactions, setLastTransactions] = useState<GamePlay[]>([]);

  const [startDate, setStartDate] = useState(location?.state?.startDate ?? 0);
  const [endtDate, setEndDate] = useState(location?.state?.endtDate ?? 0);
  const [currentPeriod, setCurrentPeriod] = useState(
    location?.state?.currentPeriod ?? GamePeriod.Week
  );
  const [chartType, setChartType] = useState<'games' | 'egld'>(
    location?.state?.chartType ?? 'egld'
  );

  useEffect(() => {
    if (transactionsRL && transactionsBJ) {
      setTransactions(
        [...transactionsRL, ...transactionsBJ].sort(
          (a, b) => b.timestamp - a.timestamp
        )
      );
    }
  }, [transactionsRL, transactionsBJ]);

  useEffect(() => {
    if (lastTransactionsRL && lastTransactionsBJ) {
      setLastTransactions(
        [...lastTransactionsRL, ...lastTransactionsBJ]
          .sort((a, b) => b.timestamp - a.timestamp)
          .slice(0, 10)
      );
    }
  }, [lastTransactionsRL, lastTransactionsBJ]);

  useEffect(() => {
    if (!startDate || !endtDate) {
      const now = Math.floor(Date.now() / 1000);
      const day = 60 * 60 * 24;

      setStartDate(now - day * 7);
      setEndDate(now);
      setCurrentPeriod(GamePeriod.Week);
    } else if (startDate && endtDate) {
      setTransactions([]);
      // setLastTransactions([]);
      fetchTransactionsBJ(10, startDate, endtDate);
      fetchTransactionsRL(10, startDate, endtDate);
    }
  }, [startDate, endtDate]);

  const changePeriod = useCallback(
    (startTimeSpan: number, endTimeSpan: number, type: GamePeriod) => {
      setStartDate(startTimeSpan);
      setEndDate(endTimeSpan);
      setCurrentPeriod(type);
    },
    []
  );

  return (
    <DashboardLayout
      contractAddress={blackJackAddress}
      isLoading={isLoadingRL || isLoadingBJ}
      loadingPercent={Math.floor((loadingPercentRL + loadingPercentBJ) / 2)}
      error={errorRL ?? errorBJ}
      transactions={transactions}
      setChartType={setChartType}
      chartType={chartType}
      filters={
        <PeriodSelector
          currentPage={routeNames.home}
          currentPeriod={currentPeriod}
          currentPeriodDates={{ start: startDate, end: endtDate }}
          onChangePeriod={changePeriod}
          isLoading={isLoadingRL || isLoadingBJ}
          chartType={chartType}
        />
      }
      stats={<DashboardStats transactions={transactions} />}
      game={GamesStats.Global}
      currentPeriodDates={{ start: startDate, end: endtDate }}
    >
      <DashboardTable
        transactions={transactions}
        lastTransactions={lastTransactions}
      />
    </DashboardLayout>
  );
};
