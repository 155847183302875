import { useState } from 'react';
import { blackJackAddress, rouletteAddress } from 'config';
import { GamePlay } from 'types';

export const DashboardTable = ({
  transactions,
  lastTransactions
}: {
  transactions: GamePlay[];
  lastTransactions: GamePlay[];
}) => {
  const [displayAll, setDisplayAll] = useState(false);

  const getBadgeColor = (value: string) => {
    if (value === 'Black Jack') return 'badge-blackjack';
    else if (value === 'Win') return 'badge-win';
    return '';
  };

  return (
    <div className='card dapp-core-component__main__card dapp-core-component__main__pb-2 dapp-core-component__main__mb-3 dapp-core-component__transactionsTable-styles__transactions-table dapp-transactions-table'>
      <div className='dapp-core-component__main__card-body dapp-core-component__main__p-0'>
        <div className='dapp-core-component__transactionsTable-styles__table-wrapper'>
          <div className='dashboard-table-title text-white-80 mx-3'>
            LAST GAMES
          </div>
          <table className='dapp-core-component__transactionsTable-styles__table'>
            <thead>
              <tr>
                <th>
                  <div className='table-col'>Game</div>
                </th>
                <th>
                  <div className='table-col'>Date</div>
                </th>
                <th>
                  <div className='table-col'>Player</div>
                </th>
                <th>
                  <div className='table-col'>Bet</div>
                </th>
                <th>
                  <div className='table-col'>Result</div>
                </th>
                <th>
                  <div className='table-col'>Payout</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {(displayAll ? transactions : lastTransactions)?.map(
                (tx, index) => (
                  <tr key={index}>
                    <td className='dapp-core-component__main__text-center'>
                      <span className='dapp-core-component__transactionsTable-styles__transaction-cell d-flex justify-content-center'>
                        {tx.game === 'BlackJack' && (
                          <a
                            href={`https://explorer.multiversx.com/accounts/${blackJackAddress}`}
                            target='_blank'
                            className='logo-bj mr-3'
                          />
                        )}
                        {tx.game === 'Roulette' && (
                          <a
                            href={`https://explorer.multiversx.com/accounts/${rouletteAddress}`}
                            target='_blank'
                            className='logo-roulette mr-3'
                          />
                        )}
                        {/* {tx.game} */}
                      </span>
                    </td>
                    <td className='dapp-core-component__main__text-center'>
                      <span className='dapp-core-component__transactionsTable-styles__transaction-cell'>
                        {new Date(tx.timestamp * 1000).toLocaleString()}
                      </span>
                    </td>
                    <td className='dapp-core-component__main__text-center'>
                      <span className='dapp-core-component__transactionsTable-styles__transaction-cell'>
                        {tx.player}
                      </span>
                    </td>
                    <td className='dapp-core-component__main__text-center'>
                      <div className='dapp-core-component__transactionsTable-styles__transaction-cell'>
                        <a
                          href={`https://explorer.multiversx.com/transactions/${tx.txId}`}
                          target='_blank'
                          className='text-white link-underline'
                        >
                          <span className='dapp-core-component__formatAmountStyles__int-amount'>
                            {isNaN(parseInt(tx.bet))
                              ? ''
                              : parseInt(tx.bet) / 10 ** 18}
                          </span>
                          <span className='dapp-core-component__formatAmountStyles__symbol'>
                            {' '}
                            EGLD
                          </span>
                        </a>
                      </div>
                    </td>
                    <td className='dapp-core-component__main__text-center'>
                      <div className='dapp-core-component__transactionsTable-styles__transaction-cell'>
                        <span className='dapp-core-component__main__badge dapp-core-component__main__badge-secondary dapp-core-component__main__badge-pill dapp-core-component__main__font-weight-light dapp-core-component__main__p-0'>
                          <div
                            className={
                              'dapp-core-component__main__badge dapp-core-component__main__badge-secondary dapp-core-component__main__badge-pill ' +
                              getBadgeColor(tx.result)
                            }
                          >
                            <div className='dapp-core-component__main__text-truncate dapp-core-component__main__text-capitalize dapp-core-component__main__text-white dapp-core-component__main__p-1 dapp-core-component__transactionsTable-styles__transaction-cell-font-small'>
                              {tx.result}
                            </div>
                          </div>
                        </span>
                      </div>
                    </td>
                    <td className='dapp-core-component__main__text-center'>
                      <div className='dapp-core-component__transactionsTable-styles__transaction-cell'>
                        <a
                          href={`https://explorer.multiversx.com/transactions/${tx.payoutTxId}`}
                          target='_blank'
                          className='text-white link-underline'
                        >
                          <span className='dapp-core-component__formatAmountStyles__int-amount'>
                            {isNaN(parseInt(tx.payout))
                              ? ''
                              : parseInt(tx.payout) / 10 ** 18}
                          </span>
                          {!isNaN(parseInt(tx.payout)) && (
                            <span className='dapp-core-component__formatAmountStyles__symbol'>
                              {' '}
                              EGLD
                            </span>
                          )}
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>

          {transactions.length > 4 && (
            <div className='mx-4 my-3 text-right'>
              <button
                className='btn px-2 py-0 stats-plus'
                type='button'
                onClick={() => setDisplayAll(!displayAll)}
              >
                {!displayAll ? '+' : '-'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
