export const rouletteAddress =
  'erd1qqqqqqqqqqqqqpgqmxxkuqwhxpwerqqs7arn5lv66hkzjp58xkuqzeukhr';
export const blackJackAddress =
  'erd1qqqqqqqqqqqqqpgqy0y2wuhajyer7tjruh3cej4uk073vt63elnsnf390l';

export const dAppName = 'Salvadorian Ape Club & Salvaki Games Dashboard';
export const dAppTitle = 'Salvadorian Ape Club & Salvaki';
export const dAppSubTitle = 'Games Dashboard';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = 'a9c67816d58683d5719b72cdc569669f';

export const apiTimeout = 60000;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
