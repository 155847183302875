import { useCallback, useEffect, useState } from 'react';
import { TransactionServerStatusesEnum } from '@multiversx/sdk-dapp/types';
// import { AxiosError } from 'axios';
import { apiTimeout, blackJackAddress } from 'config';
import { base64ToString, getTransactions, getTransactionsCount } from 'helpers';
import { useGetNetworkConfig } from 'hooks';
import { GamePlay, ServerTransactionType } from 'types';
import { useWalletData } from './Wallet';

export const useGetBlackJackData = () => {
  const {
    network: { apiAddress }
  } = useGetNetworkConfig();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [error, setError] = useState<string>();
  const [transactions, setTransactions] = useState<GamePlay[]>([]);
  const [periodTransactions, setPeriodTransactions] = useState<GamePlay[]>([]);

  const refreshPercent = useCallback((currentLoadingPercent: number[]) => {
    const toDisplay = currentLoadingPercent.splice(0, 1);
    if (toDisplay?.length > 0) setLoadingPercent(toDisplay[0]);

    return currentLoadingPercent;
  }, []);

  const fetchTransactions = useCallback(
    async (transactionsNumber: number, startDate: number, endDate: number) => {
      try {
        setIsLoading(true);
        setLoadingPercent(0);
        setError(undefined);
        setPeriodTransactions([]);

        // get tx on period
        if (startDate) {
          const dateDay = new Date(startDate * 1000).setUTCHours(0, 0, 0, 0);
          startDate = Math.floor(dateDay / 1000);
        }
        if (endDate) {
          const dateDay = new Date(endDate * 1000).setUTCHours(23, 59, 59, 0);
          endDate = Math.floor(dateDay / 1000);
        }

        const lastTx = [];

        // get last 10 txs
        // if (!transactions || transactions.length === 0) {
        lastTx.push(
          getTransactions({
            apiAddress,
            receiver: blackJackAddress,
            transactionSize: transactionsNumber * 3 + 5,
            status: TransactionServerStatusesEnum.success,
            withScResults: true,
            apiTimeout,
            before: endDate
          }).then(({ data }) => {
            const comptedLastTxs = computeTransactions([...data]);
            setTransactions(comptedLastTxs.slice(0, transactionsNumber));
          })
        );
        // }

        // calc transactions number
        let txsAvailable = 0;
        lastTx.push(
          getTransactionsCount({
            apiAddress,
            receiver: blackJackAddress,
            status: TransactionServerStatusesEnum.success,
            withScResults: false,
            apiTimeout,
            after: startDate,
            before: endDate
          }).then(({ data }) => {
            txsAvailable = data;
          })
        );

        await Promise.all(lastTx);

        if (txsAvailable > 10000) {
          setError('Too many games on the period, please reduce the period');
          setTransactions([]);
          setPeriodTransactions([]);
          return;
        }

        const maxTransactionSize = 50;
        let txData: ServerTransactionType[] = [];
        let page = 1;

        const getTxs = [];
        let percentsToDisplay: number[] = [];
        for (
          let size = 0;
          size < txsAvailable;
          size += maxTransactionSize, page++
        ) {
          percentsToDisplay.push(Math.floor((size / txsAvailable) * 100));

          getTxs.push(
            getTransactions({
              apiAddress,
              receiver: blackJackAddress,
              // condition: 'must',
              transactionSize: maxTransactionSize,
              status: TransactionServerStatusesEnum.success,
              withScResults: true,
              apiTimeout,
              page,
              after: startDate,
              before: endDate
            }).then(({ data }) => {
              percentsToDisplay = refreshPercent(percentsToDisplay);
              return data;
            })
          );
        }

        await Promise.all(getTxs).then((data) => {
          txData = data.flat();
        });

        const comptedTxs = computeTransactions(txData ?? []);
        setPeriodTransactions(comptedTxs);
      } catch (err) {
        // const { message } = err as AxiosError;
        const message = 'Error fetching transactions.';
        setError(message);
        setTransactions([]);
        setPeriodTransactions([]);
      } finally {
        setIsLoading(false);
        setLoadingPercent(100);
      }
    },
    [transactions, blackJackAddress, refreshPercent, apiAddress, apiTimeout]
  );

  function computeTransactions(trxs: ServerTransactionType[]) {
    // tx received
    const gameTxs: GamePlay[] = [];

    if (trxs?.length > 0) {
      const txs = trxs.reverse();

      txs.forEach((tx: ServerTransactionType) => {
        let endTx = undefined;
        let gameData: string[] | undefined;

        if ((tx as any)?.function === 'play' && tx?.data) {
          if (tx.status === 'success') {
            gameData = base64ToString(tx.data)?.split('@');
            const gameId = gameData[1];

            for (let index = txs.indexOf(tx) + 1; index < txs.length; index++) {
              const endTxElement: any = txs[index];

              if (IsEndTx(endTxElement)) {
                gameData = IsEndGameTx(endTxElement, gameId);
                if (gameData) {
                  endTx = endTxElement;
                  break;
                }
              }
            }
          }

          const gameResult = GetGameResult(endTx, gameData);

          if (gameResult !== 'Error') {
            gameTxs.push({
              game: 'BlackJack',
              player: tx.sender,
              bet: tx.value,
              payout:
                gameResult === 'Win' ||
                gameResult === 'Draw' ||
                gameResult === 'Black Jack'
                  ? endTx.results.find(
                      (r: any) => r.receiver === tx.sender && !r.data && r.value
                    ).value
                  : '',
              result: gameResult,
              timestamp: tx.timestamp,
              txId: tx.txHash,
              payoutTxId: endTx.txHash
            });
          }
        }
      });
    }

    return gameTxs.reverse();
  }

  function IsEndTx(endTxElement: any) {
    return (
      endTxElement?.function === 'end' &&
      endTxElement?.data &&
      endTxElement?.status === 'success'
    );
  }

  function IsEndGameTx(endTxElement: any, gameId: string) {
    const gameData = base64ToString(endTxElement.data)?.split('@');

    if (gameData?.length > 1 && gameId === gameData[1]) return gameData;
    return undefined;
  }

  function GetGameResult(endTx: any, gameData: string[] | undefined) {
    if (endTx?.data && gameData) {
      if (gameData[2] === '14') {
        return 'Win';
      } else if (gameData[2] === '0a') {
        return 'Draw';
      } else if (gameData[2] === '19') {
        return 'Black Jack';
      } else {
        return 'Lose';
      }
    }

    return 'Error';
  }

  const { preComputeWallets, computeWallets } = useWalletData();

  const [gameTransactions, setGameTransactions] = useState<GamePlay[]>([]);
  const [lastGameTransactions, setLastGameTransactions] = useState<GamePlay[]>(
    []
  );

  useEffect(() => {
    if (transactions?.length > 0) {
      setLastGameTransactions(preComputeWallets(transactions));

      computeWallets(apiAddress, transactions).then((newTxs) =>
        setLastGameTransactions(newTxs)
      );
    } else {
      setLastGameTransactions([]);
    }
  }, [transactions]);

  useEffect(() => {
    if (periodTransactions?.length > 0) {
      setGameTransactions(preComputeWallets(periodTransactions));

      computeWallets(apiAddress, periodTransactions).then((newTxs) =>
        setGameTransactions(newTxs)
      );
    } else {
      setGameTransactions([]);
    }
  }, [periodTransactions]);

  return {
    fetchTransactions,
    transactions: gameTransactions,
    lastTransactions: lastGameTransactions,
    isLoading,
    loadingPercent,
    error
  };
};
