import React from 'react';
import { faBan, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '@multiversx/sdk-dapp/UI/Loader/Loader';
import { PageState } from '@multiversx/sdk-dapp/UI/PageState/PageState';
import { GamePlay, GamesStats } from 'types';
import styles from './../dashboard.module.scss';
import { DashboardChart } from './DashboardChart';

interface DashboardLayoutProps {
  isLoading: boolean;
  loadingPercent: number;
  error?: string;
  transactions: GamePlay[];
  contractAddress: string;
  filters: any;
  stats: any;
  game: GamesStats;
  currentPeriodDates: { start: number; end: number };
  chartType: 'games' | 'egld';
  setChartType: (chart: 'games' | 'egld') => void;
}

export const DashboardLayout = ({
  children,
  isLoading,
  loadingPercent,
  error,
  transactions,
  filters,
  stats,
  game,
  currentPeriodDates,
  chartType,
  setChartType
}: React.PropsWithChildren<DashboardLayoutProps>) => {
  let body;

  if (isLoading) {
    return (
      <>
        <div className='container pt-4'>
          <div className='row'>
            <div className='col-12 mx-auto'>
              <div className={styles.transactions}>
                {filters}
                <div className='d-flex mt-10'>
                  <Loader />
                  {loadingPercent > 0 && (
                    <div className='loader-percent'>{loadingPercent} %</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (error) {
    body = (
      <div className='my-5 card'>
        <PageState icon={faBan} className='text-muted' title={error} />
      </div>
    );
  } else if (transactions.length === 0) {
    body = (
      <>
        <div className='card mb-4'>
          <PageState
            icon={faExchangeAlt}
            className='text-muted'
            title='No games during this period'
          />
        </div>
        {children}
      </>
    );
  } else {
    body = (
      <>
        {stats}
        <DashboardChart
          game={game}
          transactions={transactions}
          currentPeriodDates={currentPeriodDates}
          currentChart={chartType}
          onChangeChart={setChartType}
        />
        {children}
      </>
    );
  }

  return (
    <div className='container pt-4'>
      <div className='row'>
        <div className='col-12 mx-auto'>
          <div className={styles.transactions}>
            {filters}
            {body}
          </div>
        </div>
      </div>
    </div>
  );
};
